import {AuthConfig} from 'angular-oauth2-oidc';
import {getEnvProperty} from "./env-helper";

declare const require: any;

const authCodeFlowConfig: AuthConfig = {
  strictDiscoveryDocumentValidation: false,
  issuer: getEnvProperty('authUrl', 'https://sso.wim.cantaa.de/realms/wim-main'),
  redirectUri: window.location.origin,
  clientId: getEnvProperty('authClientId', 'wim-fe'),
  responseType: 'code',
  timeoutFactor: 0.7,
  scope: 'openid offline_access',
  showDebugInformation: true,
};

export const environment = {
  environment: 'main',
  appVersion: require('../../package.json').version,
  production: getEnvProperty('production', false),
  apiUrl: getEnvProperty('backendUrl', '/api'),
  //   DEBUG, INFO, WARN, ERROR
  logLevel: getEnvProperty('logLevel', 'DEBUG'),
  testData: getEnvProperty('testData', false),
  debugMode: getEnvProperty('debugMode', false),
  authCodeFlowConfig: authCodeFlowConfig
};
