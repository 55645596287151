<mat-form-field class="the-dense-zone-4 mt-1" floatLabel="always">
  <mat-label>search</mat-label>
  <input matInput type="text" [(ngModel)]="searchQuery"
         (keydown.enter)="search()"
         [placeholder]="'SEARCH.SEARCH' | translate"
         onclick="this.select()">
  @if (searchQuery()) {
    <button matSuffix mat-icon-button aria-label="clear" (click)="onClear()">
      <mat-icon>cancel</mat-icon>
    </button>
  }
</mat-form-field>
