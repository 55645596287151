<div class="card p-input-filled full-height">
  <p-table
    #dt
    dataKey="id"
    [value]="gridItems"
    [lazy]="true"
    selectionMode="single"
    [(selection)]="selectedItem"
    [resizableColumns]="true" columnResizeMode="expand"
    (onLazyLoad)="fetchDataViaTableComponent($event)"
    [paginator]="true"
    [first]="gridMeta.lazyLoadMeta?.first ?? 0"
    [rows]="gridMeta.lazyLoadMeta?.rows ?? 20"
    [rowsPerPageOptions]="[10, 20, 50]"
    showCurrentPageReport="true"
    currentPageReportTemplate="{first} - {last} / {totalRecords}"
    [paginatorDropdownAppendTo]="'body'"
    [totalRecords]="totalRecords"
    [sortMode]="'multiple'"
    [multiSortMeta]="gridMeta.lazyLoadMeta?.multiSortMeta"
    [filters]="gridMeta.filters!"
    [scrollable]="true" scrollHeight="var(--explorer-grid-height)"
    [tableStyle]="{ 'min-width': '50rem' }"
    styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
  >

    <ng-template pTemplate="header">
      <tr>
        <th id="defaultpicture" style="width:1px" pResizableColumn>
        </th>
        <th *showIfDebugMode id="id" style="min-width:8rem" pSortableColumn="id" pResizableColumn>
          {{ 'Id' | translate }}
          <p-sortIcon field="id"></p-sortIcon>
          <p-columnFilter type="numeric" field="id" display="menu" [showOperator]="false"></p-columnFilter>
        </th>
        <th id="productName" style="min-width:10rem" pSortableColumn="productName" pResizableColumn>
          {{ 'PRODUCT.NAME' | translate }}
          <p-sortIcon field="productName"></p-sortIcon>
          <p-columnFilter type="text" field="productName" display="menu" [showOperator]="false"></p-columnFilter>
        </th>
        <th id="category" style="min-width:10rem" pSortableColumn="category" pResizableColumn>
          {{ 'TOOLCATEGORY' | translate }}
          <p-sortIcon field="category"></p-sortIcon>
          <p-columnFilter type="text" field="category" display="menu" [showOperator]="false"></p-columnFilter>
        </th>
        <th id="rfid" style="min-width:10rem" pSortableColumn="rfid" pResizableColumn>
          {{ 'RFID' | translate }}
          <p-sortIcon field="rfid"></p-sortIcon>
          <p-columnFilter type="text" field="rfid" display="menu" [showOperator]="false"></p-columnFilter>
        </th>
        <th id="serialNumber" style="min-width:12rem" pSortableColumn="serialNumber" pResizableColumn>
          {{ 'SERIALNUMBER' | translate }}
          <p-sortIcon field="serialNumber"></p-sortIcon>
          <p-columnFilter type="text" field="serialNumber" display="menu" [showOperator]="false"></p-columnFilter>
        </th>
        <th id="manufacturerName" style="min-width:12rem" pSortableColumn="manufacturerName" pResizableColumn>
          {{ 'MANUFACTURER.MANUFACTURER' | translate }}
          <p-sortIcon field="manufacturerName"></p-sortIcon>
          <p-columnFilter type="text" field="manufacturerName" display="menu" [showOperator]="false"></p-columnFilter>
        </th>
        <th id="locationPretty" style="min-width:13rem" pSortableColumn="locationPretty" pResizableColumn>
          {{ 'LOCATION.LOCATION' | translate }}
          <p-sortIcon field="locationPretty"></p-sortIcon>
          <p-columnFilter type="text" field="locationPretty" display="menu" [showOperator]="false"></p-columnFilter>
        </th>
        <th id="supplierPretty" style="min-width:10rem" pSortableColumn="supplierPretty" pResizableColumn>
          {{ 'SUPPLIER' | translate }}
          <p-sortIcon field="supplierPretty"></p-sortIcon>
          <p-columnFilter type="text" field="supplierPretty" display="menu" [showOperator]="false"></p-columnFilter>
        </th>
        <th id="acquisitionDate" style="min-width:13rem" pSortableColumn="acquisitionDate" pResizableColumn>
          {{ 'DATE.ACQUISITION' | translate }}
          <p-sortIcon field="acquisitionDate"></p-sortIcon>
          <p-columnFilter type="date" field="acquisitionDate" display="menu" [showOperator]="false"></p-columnFilter>
        </th>
        <th id="lastTransactionDate" style="min-width:16rem" pSortableColumn="lastTransactionDate" pResizableColumn>
          {{ 'DATE.LAST_TRANSACTION_DATE' | translate }}
          <p-sortIcon field="lastTransactionDate"></p-sortIcon>
          <p-columnFilter type="date" field="lastTransactionDate" display="menu" [showOperator]="false"></p-columnFilter>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-tool>
      <tr [pSelectableRow]="tool" (dblclick)="onRowDblClick($event, tool.treeNode)">
        <td style="padding: 0px;">
          <img id="{{ toolDocumentService.generateImgElementId4Tool(tool.id) }}" class="img-defaultphoto-icon" src="">
        </td>
        <td *showIfDebugMode>{{ tool.id }}</td>
        <td>{{ tool.productName }}</td>
        <td>{{ tool.category }}</td>
        <td>{{ tool.rfid }}</td>
        <td>{{ tool.serialNumber }}</td>
        <td>{{ tool.manufacturerName }}</td>
        <td>{{ tool.locationPretty }}</td>
        <td>{{ tool.supplierPretty }}</td>
        <td>{{ tool.acquisitionDate }}</td>
        <td>{{ tool.lastTransactionDate }}</td>
      </tr>
    </ng-template>

    <ng-template id="buttonCreate" pTemplate="paginatorleft">

      <div class="pt-4">
        <button mat-flat-button color="primary" class="mt-0" (click)="createNew()"
                *hasPermission="Permission.TOOL_CREATE">
          <mat-icon>add</mat-icon>
          {{ 'CREATE' | translate }}
        </button>
        <button mat-flat-button [disabled]="!selectedItem" (click)="delete()" class="mx-1 mt-0"
                *hasPermission="Permission.TOOL_DELETE">
          <mat-icon>clear</mat-icon>
          {{ 'DELETE.DELETE' | translate }}
        </button>

        <wim-search-input [searchQuery]="gridMeta.searchQuery" (onSearch)="onSearch($event)" class="mx-2"/>
      </div>

    </ng-template>

  </p-table>
</div>
